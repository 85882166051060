<template>
    <div class="page-wrapper">
        <PageHeader>
            <template v-slot:left>
                <div class="h3">{{ $t('app.page.inquiries.list.title') }}</div>
            </template>

            <template v-slot:right>
                <v-text-field
                    class="search"
                    v-model="filters.inquirySearch"
                    density="compact"
                    :label="$t('app.page.inquiries.list.form.search.placeholder')"
                    prepend-inner-icon="icon-search"
                    clearable
                    single-line
                    hide-details
                    @input="searchData()"
                    v-on:keyup.enter="searchData(false)"
                ></v-text-field>
            </template>

            <template v-slot:tools>
                <transition name="toggle-header">
                <template
                    v-if="!isSearchMode">
                    <v-tabs
                        class="tabs"
                        grow
                        v-model="filters.inquiryStatus"
                        @update:modelValue="handleInquiryStatusChange"
                    >
                        <template
                            v-for="inquiryStatus in inquiryStatuses"
                            :key="inquiryStatus"
                        >
                            <v-tab :value="inquiryStatus">{{ $t(`app.inquiries.status.${inquiryStatus}.tab`) }}</v-tab>
                        </template>
                    </v-tabs>
                </template>
                </transition>
            </template>

        </PageHeader>

        <PageContent>

            <Overlay :visible="isWaiting || filters.search.isSearching"></Overlay>

            <div class="page-limiter">

                <!-- Normal mode -->
                <Transition name="slide-vertical">
                    <div v-if="!isSearchMode" key="normal">

                        <div class="heading">
                            <div class="h1">{{ $t(`app.inquiries.status.${filters.inquiryStatus}.title`) }}</div>

                            <div class="filters">
                                <v-chip-group
                                    filter
                                    mandatory="force"
                                    v-model="filters.inquiryKind"
                                    @update:modelValue="handleInquiryKindChange"
                                >
                                    <v-chip value="all">{{ $t('app.inquiries.filters.inquirykinds.all') }}</v-chip>
                                    <template
                                        v-for="inquiryKind in inquiryKinds"
                                        :key="inquiryKind.id"
                                    >
                                        <v-chip :value="inquiryKind.code">{{ $trans(inquiryKind, 'name') }}</v-chip>
                                    </template>
                                </v-chip-group>
                            </div>
                        </div>

                        <div class="inquiries" v-if="inquiries.items">
                            <TransitionGroup
                                name="fade-staggering"
                                :style="{ '--total': inquiries.items.length }"
                                tag="div"
                            >
                                <ItemInquiryList
                                    v-for="inquiry, i in inquiries.items"
                                    :key="inquiry.id"
                                    :style="{'--i': i}"
                                    :inquiry="inquiry"
                                ></ItemInquiryList>
                            </TransitionGroup>

                            <Pagination
                                v-if="inquiries.itemsTotal > inquiries.itemsPerPage"
                                :itemsTotal="inquiries.itemsTotal"
                                :itemsPerPage="inquiries.itemsPerPage"
                                v-model="inquiries.currentPage"
                                @update:modelValue="refreshData()"
                            >
                            </Pagination>

                            <Transition name="fade">
                                <div v-if="!isWaiting && inquiries.itemsTotal === 0 && filters.inquiryStatus === 'pending'  && filters.inquiryKind === 'all'">
                                    <div class="no-result">
    <!--                                    <v-icon icon="icon-time"></v-icon>-->
                                        <h2>{{ $t('app.inquiries.no_result.primary.title') }}</h2>
                                        {{ $t('app.inquiries.no_result.primary.text') }}
    <!--                                    <v-btn class="button"-->
    <!--                                           v-if="authStore.undertakerConfig.pageConfigurator"-->
    <!--                                           :href="authStore.undertakerConfig.pageConfigurator"-->
    <!--                                           target="_blank"-->
    <!--                                    >-->
    <!--                                        {{ $t('app.inquiries.no_result.primary.button') }}-->
    <!--                                    </v-btn>-->
                                    </div>
                                </div>
                                <div v-else-if="!isWaiting && inquiries.itemsTotal === 0">
                                    <div class="no-result">
    <!--                                    <v-icon icon="icon-search"></v-icon>-->
                                        <h2>{{ $t('app.inquiries.no_result.secondary.title') }}</h2>
                                        {{ $t('app.inquiries.no_result.secondary.text') }}
    <!--                                    <v-btn class="button"-->
    <!--                                           @click="clearSearchFilters(); refreshData()"-->
    <!--                                    >-->
    <!--                                        {{ $t('app.inquiries.no_result.secondary.button') }}-->
    <!--                                    </v-btn>-->
                                    </div>
                                </div>
                            </Transition>

                        </div>
                    </div>

                    <!-- Search mode -->
                    <div v-else key="search">

                        <div class="heading">
                            <div class="h1">{{ $t('app.inquiries.search.title') }} “{{ filters.inquirySearch }}”</div>
                        </div>

                        <div class="inquiries">
                            <TransitionGroup
                                v-if="filters.search.results"
                                name="fade-staggering"
                                :style="{ '--total': filters.search.results.length }"
                                tag="div"
                            >
                                <ItemInquiryList
                                    v-for="inquiry, i in filters.search.results"
                                    :key="inquiry.id"
                                    :style="{'--i': i}"
                                    :inquiry="inquiry"
                                    :isSearchResult="true"
                                ></ItemInquiryList>
                            </TransitionGroup>

                            <div v-if="filters.search.resultsTotal === 0">
                                <div class="no-result">
    <!--                                <v-icon icon="icon-search"></v-icon>-->
                                    <h2>{{ $t('app.inquiries.search.no_result.title') }} “{{ filters.inquirySearch }}”</h2>
                                    {{ $t('app.inquiries.search.no_result.text') }}
    <!--                                <v-btn class="button"-->
    <!--                                       @click="filters.inquirySearch = null"-->
    <!--                                >-->
    <!--                                    {{ $t('app.inquiries.search.no_result.button') }}-->
    <!--                                </v-btn>-->
                                </div>
                            </div>
                        </div>

                    </div>
                </Transition>

<!--                <pre style="line-height: 1;"><small>{{ filters }}</small></pre>-->

<!--                <pre style="line-height: 1;"><small>{{ inquiries }}</small></pre>-->

            </div>
        </PageContent>
    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global.store'
import { useAuthStore } from '@/stores/auth.store'
// import { useMessageStore } from '@/stores/message.store'

import PageHeader from "@/components/commons/PageHeader.vue"
import PageContent from "@/components/commons/PageContent.vue"
import Overlay from "@/components/commons/Overlay.vue"
import Pagination from "@/components/commons/Pagination.vue"
import ItemInquiryList from "@/components/items/ItemInquiryList.vue"

import { supportedStatuses } from '@/middleware/inquiries'
import InquiriesService from '@/middleware/inquiries'
import InquiryKindsService from '@/middleware/inquiryKinds'

export default {
    components: { PageHeader, PageContent, Overlay, Pagination, ItemInquiryList },

    setup() {
        const globalStore = useGlobalStore()
        const authStore = useAuthStore()
        // const messageStore = useMessageStore()

        return {
            globalStore,
            authStore,
            // messageStore,
        }
    },

    data() {
        return {
            inquiries: {
                items: [],
                itemsPerPage: 6,
                itemsTotal: 0,
                currentPage: 1,
            },
            inquiryKinds: null,
            inquiryStatuses: null,
            isInquiriesLoaded: false,
            isInquiryKindsLoaded: false,
            isWaiting: true,
            filters: {
                inquiryStatus: null,
                inquiryKind: null,
                inquirySearch: null,
                search: {
                    isSearching: false,
                    debounceInterval: 600,
                    debounceInstance: null,
                    results: [],
                    resultsTotal: null,
                },
            },
        }
    },

    created() {

        // Set the initial state of search filters
        this.clearSearchFilters()

        // Load the initial data
        this.loadData()

    },

    computed: {

        // Boolean helper to know when to switch the view to search mode
        isSearchMode(){

            // If we are not searching, clear the search results
            if(!this.filters.inquirySearch || this.filters.inquirySearch.length === 0){
                this.clearSearchResults()
            }

            return this.filters.inquirySearch && this.filters.inquirySearch.length > 0
        }
    },

    methods: {

        /* region [data manipulation] */

        loadData() {

            // Retrieve the inquiries categories
            InquiryKindsService.getAll()
            .then((response) => {
                // Exclude some inquiry kinds from the result list
                let allInquiryKinds = this.$extract(response)
                let filteredInquiryKinds = allInquiryKinds.filter(item => item.code !== 'establishment')
                this.inquiryKinds = filteredInquiryKinds

                this.isInquiryKindsLoaded = true
            })

            // Retrieve the inquiries statuses (but remove the unsupported ones)
            this.inquiryStatuses = this.globalStore.sf.inquiryStatuses.filter(status => supportedStatuses.includes(status))

            // Retrieve the products list
            this.refreshData()

        },

        refreshData(page = this.inquiries.currentPage) {
            this.isWaiting = true

            // Retrieve the current undertaker inquiries list
            InquiriesService.getPage(page, this.inquiries.itemsPerPage, this.authStore.undertakerId, this.filters.inquiryStatus, this.filters.inquiryKind)
            .then((response) => {
                this.inquiries.items = this.$extract(response)
                this.inquiries.itemsTotal = response.data['hydra:totalItems']
            })
            .finally(() => {
                this.isWaiting = false
            });

        },

        // Methods that retrieves inquiries that match a search term
        // NOTE : this method use a debounce to prevent too much calls when typing. You can pass false in parameter to skip this debounce (when hitting enter for instance)
        searchData(withDebounce = true) {
            // this.clearSearchResults()

            if(this.isSearchMode && !this.filters.search.isSearching){

                clearTimeout(this.filters.search.debounceInstance)
                this.filters.search.debounceInstance = setTimeout(() => {

                    this.filters.search.isSearching = true

                    InquiriesService.search(this.authStore.undertakerId, this.filters.inquirySearch)
                    .then((response) => {
                        this.filters.search.results = this.$extract(response)
                        this.filters.search.resultsTotal = response.data['hydra:totalItems']
                    })
                    .finally(() => {
                        this.filters.search.isSearching = false
                    });

                }, withDebounce ? this.filters.search.debounceInterval : 0)
            }
        },

        /* endregion */

        /* region [event handlers] */

        handleInquiryStatusChange(value) {
            this.inquiries.currentPage = 1;
            this.refreshData()
        },

        handleInquiryKindChange(value) {
            this.inquiries.currentPage = 1;
            this.refreshData()
        },

        /* endregion */

        /* region [helpers] */

        clearSearchFilters() {
            this.filters.inquiryStatus = 'pending'
            this.filters.inquiryKind = 'all'
            this.filters.inquirySearch = null
        },

        clearSearchResults() {
            this.filters.search.results = null
            this.filters.search.resultsTotal = null
        },

        /* endregion */

    },

}
</script>

<style lang="scss" scoped>
@import '~styles/_var_app.scss';

.search{
    flex: 0 1 270px;

    &::v-deep(.v-label) {
        margin-top: 4px;
        white-space: nowrap;
    }
}

.tabs{
    --v-tabs-height: 50px;

    @media (min-width: $s-mobile) {
        --v-tabs-height: 70px;
    }

    .v-tab.v-tab{
        min-width: inherit;
        padding: 0 2px;

        font-size: 14px;

        @media (min-width: $s-mobile) {
            font-size: 16px;
        }
    }
}

.heading{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 6px;
}

.inquiries{

    .inquiry{

        margin-bottom: 15px;

        .wrapper{
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .picture{
            height: 40px;
            width: 40px;
            flex: 0 0 auto;

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .name{
        }

        .status{
            font-weight: normal;
            font-size: 12px;
        }

        .infos{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 auto;
            margin-left: auto;
            gap: 6px 10px;
        }
    }
}

.no-result{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;

    > .v-icon{
        font-size: 50px;
        margin-bottom: 4px;
    }

    .button{
        margin-top: 20px;
    }
}

</style>

